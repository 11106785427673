<template>
   <div class="card mb-0 cursor-pointer" ref="noticia" @click="visualizar">
      <div class="card-img overflow-hidden">
         <img :src="noticia.icone == null ? '' : noticia.icone" class="card-img-top" alt="noticia" @error="imageError">
      </div>
      <div class="card-body">
         <h5 class="limitador-1">{{ noticia.titulo == null || noticia.titulo.trim().length == 0 ? 'Sem título' : noticia.titulo }}</h5>
         <span class="text-secondary">{{ noticia.data.dayOfMonth.toString().padStart(2, '0') +'/'+ noticia.data.monthValue.toString().padStart(2, '0') +'/'+ noticia.data.year }} {{ noticia.data.hour.toString().padStart(2, '0') +':'+ noticia.data.minute.toString().padStart(2, '0') }}</span>
      </div>
   </div>
</template>

<script>

import router from '@/router'
import { mapState } from 'vuex'

export default {
   name: 'Noticia',
   props: ['noticia', 'index'],
   computed: {
		...mapState({
			urlRest: state => state.urlRest,
			tabs: state => state.tabs
		})
	},
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      visualizar : function () {
         let tabNoticia = this.tabs.find(tab => tab.nome == 'Notícia' && tab.query.id == this.noticia.id)
         router.push('/noticia/'+ (tabNoticia ? tabNoticia.id : this.tabs.length) +'?id='+ this.noticia.id +'&titulo='+ this.noticia.titulo)
      }
   }
}

</script>

<style scoped>

img {
	aspect-ratio: 3/2;
	object-fit: cover;
   transition: all .3s ease-out 0s;
}

.card:hover img {
   transform: scale(1.1) rotate(1deg);
}

h5 {
   font-size: 15px !important;
   margin-bottom: 5px !important;
}

span {
   font-size: 12px !important;
   display: block;
}

</style>