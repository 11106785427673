<template>
	<div class="row">
		<div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col mb-3">
                     <label><i class="far fa-search color-theme font-11 me-1"></i> Pesquisar</label>
                     <input type="text" class="form-control" v-model="pesquisa.valor" maxlength="200" v-focus @keyup.enter="buscarPostagens(0)" placeholder="Busque por título da notícia" />
                  </div>
                  <div class="col-2 px-0 mb-3 align-self-end">
							<button type="button" class="btn btn-primary w-100" @click="buscarPostagens(0)"><i class="fas fa-search font-13"></i></button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12">
         <div class="cards-150 cards-sm-250">
            <noticia v-for="(noticia, index) in pesquisa.retorno.resultado" :key="index" :index="index" :noticia="noticia" @editar="abrirPostagem($event)" />
         </div>
      </div>

      <div class="col-12 px-1 mt-3 mb-2 text-center" v-if="pesquisa.retorno.resultado.length > 0 && pesquisa.retorno.resultado.length % 50 == 0 && (pesquisa.retorno.valor == null || String(pesquisa.retorno.valor).trim().length == 0)">
         <button type="button" class="btn btn-secondary btn-md font-12" @click="buscarPostagens(null)">Ver mais</button>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'
import noticia from '@/components/Noticia.vue'

export default {
	name: 'Notícias',
	data : function () {
      return {
         pesquisa: {'pagina': 0, 'valor': '', 'retorno': {'resultado': [], 'valor': ''}},
      }
   },
   computed: {
		...mapState({
			urlRest: state => state.urlRest,
			tabs: state => state.tabs
		})
	},
   watch: {
		'$route.params.idTab': function () {
         this.buscarPostagens(0)
      }
	},
   components: {
		noticia
	},
	methods: {
		buscarPostagens : function (pagina) {
			this.$store.dispatch('alternarTelaCarregamento', true)
         this.pesquisa.pagina = pagina == null ? ++this.pesquisa.pagina : pagina

			this.$axios({
				method: 'post',
				url: this.urlRest + 'configuracoes/searchNoticias',
				headers: {
					'Content-Type': 'application/json'
            },
				data: this.pesquisa

			}).then(response => {
            if (this.pesquisa.pagina == 0) {
               this.pesquisa.retorno.resultado = []
            }

            this.pesquisa.retorno.valor = response.data.valor

            response.data.resultado.forEach(p => {
               this.pesquisa.retorno.resultado.push(p)
            });
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogarAdmin')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      }
	},
	mounted() {
      this.buscarPostagens(0)
	}
}

</script>